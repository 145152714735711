import axiosMethodRequest from '../../config/service'
import { GET_ACTIONS_CENTER, GET_PUBLISHER_ACTIONS_CENTER, GET_BRAND_ACTIVITY } from '../../redux/brandActionCenter/actionTypes'

export const CreateActionCenterApicall = (data, type) => {
    return dispatch => {
        dispatch({
            type: type,
            payload: { data: {}, error: {}, loader: true }
        })
        return axiosMethodRequest('POST', "brandActionCenter/createActionCenter", data).then((response) => {
            if (response && response.data && response.data.respCode) {
                dispatch({
                    type: type,
                    payload: { data: response.data, error: {}, loader: false }
                })
            } else if (response && response.data && response.data.errorCode) {
                dispatch({
                    type: type,
                    payload: { data: {}, error: response.data, data: "", loader: false }
                })
            }

        })
    }
}

export const GetActionCenterApicall = (filterCriteria) => {
    return dispatch => {
        dispatch({
            type: GET_ACTIONS_CENTER,
            payload: { data: {}, error: {}, loader: true }
        })
        filterCriteria = `?filter=${JSON.stringify(filterCriteria)}`;
        return axiosMethodRequest('GET', "brandActionCenter" + filterCriteria).then((response) => {
            if (response && response.data && response.data.respCode) {
                dispatch({
                    type: GET_ACTIONS_CENTER,
                    payload: { data: response.data.brandActionCenterList, pagination: response.data.pagination, error: {}, loader: false }
                })
            } else if (response && response.data && response.data.errorCode) {
                dispatch({
                    type: GET_ACTIONS_CENTER,
                    payload: { data: {}, error: response.data, data: [], loader: false }
                })
            }
        })
    }
}

export const submitUploadedFileApicall = (id, callBack) => {
    return dispatch => {
        return axiosMethodRequest('POST', "brandActionCenter/upload/" + id).then((response) => {
            callBack(response.data)
        })
    }
}

export const DeleteActionCenterApicall = (id, callBack) => {
    return dispatch => {
        return axiosMethodRequest('DELETE', "brandActionCenter/" + id).then((response) => {
            callBack(response.data)
        })
    }
}

export const updateBrandIdApicall = (data, callBack) => {
    return dispatch => {
        return axiosMethodRequest('POST', "brandActionCenter/updateBrandId", data).then((response) => {
            callBack(response.data)
        })
    }
}

export const checkPublisherActionCenterStatusApicall = (data, callBack) => {
    return dispatch => {
        return axiosMethodRequest('GET', "brandActionCenter/checkPublisherActionCenterStatus/" + data._id).then((response) => {
            callBack(response.data)
        })
    }
}

// export const downloadFileApicall = (data, callBack) => {
//     return dispatch => {
//         return axiosMethodRequest('GET', "brandActionCenter/downloadFile/" + data._id).then((response) => {
//             callBack(response.data)
//         })
//     }
// }

export const getCAMLinkDetailsAction = (data, callback) => {
    return dispatch => {
        return axiosMethodRequest('GET', "brandActionCenter/getCAMLinkDetails/" + data._id).then((response) => {
            callback(response.data);
        })
    }
}

export const sendMailToUserAction = (data, callback) => {
    return dispatch => {
        return axiosMethodRequest('POST', "brandActionCenter/sendMailToUser", data).then((response) => {
            callback(response.data);
        })
    }
}

export const updateActionCenterAction = (data, callback) => {
    return dispatch => {
        return axiosMethodRequest('POST', "brandActionCenter/updateActionCenter", data).then((response) => {
            callback(response.data);
        })
    }
}

export const getUserActivitiesAction = (data, callback) => {
    return dispatch => {
        return axiosMethodRequest('GET', "brandActionCenter/getUserActivities/" + data._id).then((response) => {
            callback(response.data);
        })
    }
}

export const GetPublisherActionCenterApicall = (filterCriteria) => {
    return dispatch => {
        dispatch({
            type: GET_PUBLISHER_ACTIONS_CENTER,
            payload: { data: {}, error: {}, loader: true }
        })
        filterCriteria = `?filter=${JSON.stringify(filterCriteria)}`;
        return axiosMethodRequest('GET', "brandActionCenter/getPublisherActionCenter" + filterCriteria).then((response) => {
            if (response && response.data && response.data.respCode) {
                dispatch({
                    type: GET_PUBLISHER_ACTIONS_CENTER,
                    payload: { data: response.data.publisherActionCenterList, pagination: response.data.pagination, error: {}, loader: false }
                })
            } else if (response && response.data && response.data.errorCode) {
                dispatch({
                    type: GET_PUBLISHER_ACTIONS_CENTER,
                    payload: { data: {}, error: response.data, data: [], loader: false }
                })
            }
        })
    }
}

export const getPublisherBrandList = (reqData, callback) => {
    return dispatch => {
        return axiosMethodRequest('POST', "brandActionCenter/getPublisherBrandList", reqData).then((response) => {
            callback(response.data);
        })
    }
}


export const updatePublisherActionCenterCall = (data, callback) => {
    return dispatch => {
        return axiosMethodRequest('POST', "brandActionCenter/updatePublisherActionCenter", data).then((response) => {
            callback(response.data);
        })
    }
}

export const addBrandBranchFromActionCenterAction = (data) => {
    return dispatch => {
        return axiosMethodRequest('POST', "brandActionCenter/addBrandBranchFromActionCenter", data).then((response) => {
        })
    }
}

export const releaseBrandBranchAction = () => {
    return dispatch => {
        return axiosMethodRequest('GET', "brandActionCenter/releaseBrandBranch").then((response) => {
        })
    }
}

export const getBrandListForSendMailAction = (data, callback) => {
    return dispatch => {
        return axiosMethodRequest('POST', "brandActionCenter/getBrandListForSendMail", data).then((response) => {
            callback(response.data);
        })
    }
}

export const getActionCenterAnalytics = (callback) => {
    return dispatch => {
        return axiosMethodRequest('GET', "brandActionCenter/getActionCenterAnalytics").then((response) => {
            callback(response.data);
        })
    }
}

export const addBrandBranchForSalesApicall = (formdata, callBack) => {
    return dispatch => {
        return axiosMethodRequest('POST', "brandActionCenter/addBrandBranchForSales", formdata).then((response) => {
            callBack(response.data)
        })
    }
}

export const createTicketsApicall = (data, callBack) => {
    return dispatch => {
        return axiosMethodRequest('POST', "createTickets", data).then((response) => {
            callBack(response.data);
        })
    }
}

export const updateTicketsApicall = (data, callBack) => {
    return dispatch => {
        return axiosMethodRequest('PUT', "createTickets", data).then((response) => {
            callBack(response.data);
        })
    }
}

export const getCreateTicketsApicall = (filterCriteria, callBack) => {
    return dispatch => {
        filterCriteria = `?filter=${JSON.stringify(filterCriteria)}`;
        return axiosMethodRequest('GET', "createTickets" + filterCriteria).then((response) => {
            callBack(response.data);
        })
    }
}

export const updateTicketStatusApicall = (data, callBack) => {
    return dispatch => {
        return axiosMethodRequest('POST', "createTickets/updateStatus", data).then((response) => {
            callBack(response.data);
        })
    }
}

export const deleteTicketApicall = (data, callBack) => {
    return dispatch => {
        return axiosMethodRequest('POST', "createTickets/deleteTicket", data).then((response) => {
            callBack(response.data);
        })
    }
}

export const exportAmexPhaseBrand = (data, callback) => {
    return dispatch => {
        return axiosMethodRequest('POST', "brandActionCenter/exportAmexPhaseBrand", data).then((response) => {
            callback(response.data);
        })
    }
}

export const skipCampaignStepApiCall = (id, callBack) => {
    return dispatch => {
        return axiosMethodRequest('POST', "brandActionCenter/skipCampaignStep/" + id).then((response) => {
            callBack(response.data)
        })
    }
}

export const getBrandActivityApicall = (filterCriteria) => {
    return dispatch => {
        dispatch({
            type: GET_BRAND_ACTIVITY,
            payload: { data: {}, error: {}, loader: true }
        })
        filterCriteria = `?filter=${JSON.stringify(filterCriteria)}`;
        return axiosMethodRequest('GET', "brands/getBrandActivity" + filterCriteria).then((response) => {
            if (response && response.data && response.data.respCode) {
                dispatch({
                    type: GET_BRAND_ACTIVITY,
                    payload: { data: response.data.brandList, pagination: response.data.pagination, error: {}, loader: false }
                })
            } else if (response && response.data && response.data.errorCode) {
                dispatch({
                    type: GET_BRAND_ACTIVITY,
                    payload: { data: {}, error: response.data, data: [], loader: false }
                })
            }
        })
    }
}


export const getBrandActivityByIdApicall = (brandId, callBack) => {
    return dispatch => {
        return axiosMethodRequest('GET', "brands/getBrandActivityById?brandId=" + brandId).then((response) => {
            if (response && response.data && response.data.respCode) {
                callBack({ activityList : response.data.activityList, campaignDetail : response.data.campaignDetail });
            } else if (response && response.data && response.data.errorCode) {
                callBack({ activityList : [], parentCampaign : {} });
            }
        })
    }
}

export const getSignupUsersApicall = (filterCriteria, body, callBack) => {
    return dispatch => {
        filterCriteria = `?filter=${JSON.stringify(filterCriteria)}`;
        return axiosMethodRequest('POST', "users/getSignupUsers" + filterCriteria, body).then((response) => {
            callBack(response.data);
        })
    }
}

export const exportSignupUsersApicall = (body, callBack) => {
    return dispatch => {
        return axiosMethodRequest('POST', "users/exportSignupUsers", body).then((response) => {
            callBack(response.data);
        })
    }
}