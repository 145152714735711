const config = {

  //local
  // apiUrl: 'http://localhost:34977/api/',
  // imgUrl: 'http://localhost:34977/images',
  // docsUrl: 'https://offersapi.krowdit.com/',


  // test
  // apiUrl: 'https://offersapi-test.krowdit.com/api/',
  // imgUrl: 'https://offersapi-test.krowdit.com/images/',
  // docsUrl: 'https://offersapi-test.krowdit.com/',

  // // client obm site
  // apiUrl: 'https://obmapi.krowdit.com/api/',
  // imgUrl: 'https://obmapi.krowdit.com/images/',
  // docsUrl: 'https://obmapi.krowdit.com/',

  // client offers site
  apiUrl: process.env.REACT_APP_APIURL,
  imgUrl: process.env.REACT_APP_IMGURL,
  docsUrl: process.env.REACT_APP_DOCSURL,
  guestUrl: process.env.REACT_APP_GUESTURL,
  adminUrl : process.env.REACT_APP_ADMINURL,

  // Incoming Call Sound
  callRingtone: 'https://vy.api.dosystemsinc.com/docs/skype_call.mp3',
  serverErrMessage: 'Could Not reach server',
  entityType: 'employee',
  templateColor: '#0e4768',
  whiteColor: '#ffffff',
  blackColor: '#000',
  appName: 'meet At',
  //regex
  borderValidation: false,
  messages: true,
  emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  passwordRegex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/,
  aadharcardNumberRegex: /^([0-9]){12}$/,
  pancardNumberRegex: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
  phoneNumberRegex: /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  // server response codes
  updateResCode: 205,
  deleteResCode: 206,
  ddMMYYYFormat: 'DD/MM/YYYY',
  datePlaceholder: '--/--/----',
  dateFormat: 'MM/DD/YYYY',
  dateTabularFormat: 'MMM DD YYYY',
  dateDisplayModalFormat: 'DD MMM YYYY',
  dateDBFormat: 'MM-DD-YYYY',
  dateDayMonthFormat: 'DD-MM-YYYY',
  basicDateFromat: 'MM/DD/YYYY HH:mm A',
  descDateFromat: 'MMM DD YYYY HH:mm A',
  timeFormat: 'HH:mm',
  syncTimeFormat: 'hh:mm A, MM-DD-YYYY',
  lastModifiedDateFormat: 'MM/DD/YYYY HH:mm',
  dateTimeFormat: 'MM/DD/YYYY hh:mm A',
  fullDateFormat: 'YYYY-MM-DD HH:mm:ss',
  dbDateFormat: 'YYYY-MM-DD[T]HH:mm:ss.SSS',
  dbOnlyDateFormat: 'YYYY-MM-DD[T]00:00:00Z',
  ESTTimezone: "America/New_York",
  monthYearFormat: "MMM YYYY",
  dayMonthYearFormat: ' MMM  DD, YYYY',
  noView: 'noView',
  edit: 'edit',
  view: 'view',
  videoScreenBackgroundColor: '#220F0F',
  avatarBgColor: 'green',
  loginName: 'meet At'
};
export default config;
