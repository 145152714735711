export const getDashboardstate = (state) => {
  return state.dashboard.dashboardData
};

export const getUserstate = (state) => {
  return state.user
};


export const getDashboardLoaderstate = (state) => {
  return state.dashboard.dashboardData.loader
};

export const getGroupBrandListstate = (state) => {
  return state.dashboard.groupBrand
};

export const getParentCampaignState = (state) => {
  return state.dashboard.parentCampaignDetails
};
