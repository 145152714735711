// import axios from '../../utils/AxiosWrapper'
import axios from 'axios';
import config from '../../config/config'
import axiosMethodRequest from '../../config/service'
import { ClearSessionValue, GetSessionValue, SetSessionValue } from '../../utils/sessionStorage';
export function signout() {
	return {
		type: 'SIGN_OUT'
	}
}

export const setUserAction = user => {
	return {
		type: 'SET_USER',
		payload: user
	}
}

export const loaderAction = loader => {
	return {
		type: 'SET_USER',
		loader: loader
	}
}

export const setEntityDetails = entityDetails => {
	return {
		type: 'SET_USER_ENTITY_DETAILS',
		payload: entityDetails || {}
	}
}

export const setSettingsData = data => {
	return {
		type: 'SET_SETTINGS_DATA',
		payload: data
	}
}
//getting settings data
export const settingsAction = (data, callback) => {
	return dispatch => {
		axiosMethodRequest('GET', 'settings').then(async (response) => {
			if (response && response.data && response.data.respCode) {
				data = await response.data.settings[0]
				if (callback) {
					callback(response.data.settings[0])
				}
				await dispatch(setSettingsData(response.data.settings[0]))
			}
		})
	}
}
export const signupAction = (data, callback) => {
	return dispatch => {
		return axios.post('/auth/signup', data)
			.then(res => {
				dispatch(setUserAction(res.data))
				if (callback)
					callback()
			})
			.catch(err => {
				if (callback)
					callback(err)
			})
	}
}

export const createPasswordAction = (data, callback) => {
	return dispatch => {
		axiosMethodRequest('POST', 'auth/changeRecoverPassword', data).then((res) => {
			if (res && res.data && res.data.respCode) {
				callback(res.data.respMessage, 'success')
			} else {
				callback(res.data.errorMessage, 'error')
			}
		})
	}
}

export const getEntityDetails = (entityType, updateUserLoadState, callback) => {
	let userDetails = GetSessionValue('loginCredentials')
	return dispatch => {
		let apiUrl = `${entityType}s/${userDetails['_id']}`
		return axiosMethodRequest('GET', apiUrl)
			.then(res => {
				dispatch(setEntityDetails(res.data))
				if (updateUserLoadState) {
					dispatch(setUserAction({ loadComplete: true }))
				}
				if (res && res.data && res.data.respCode) {
					let data = res.data.details
					let userDetails = GetSessionValue('user')
					if (data && userDetails) {
						// data.entityType = 'brand'
						data.entityType = data.role
						// data.tc_accepted = data.isTAndCAccepted
						data.accessToken = userDetails.accessToken
						SetSessionValue('user', data)
						SetSessionValue('loginCredentials', data)
						if (callback)
							return callback(res.data.details, 'success')
					}
				} else {
					if (callback)
						return callback(res.data.details, 'error')
				}
			})
	}
}

export const loginAction = (data, callback) => {
	data.entityType = config.entityType
	return dispatch => {
		return axiosMethodRequest('POST', 'auth/login', data)
			.then(async (response) => {
				if (response && response.data && response.data.respCode) {
					let data = response.data.details
					data.accessToken = response.data.accessToken
					await SetSessionValue('loginCredentials', data)
					if(response.data && response.data.details && response.data.details.role == "bank") {
						await SetSessionValue('distributor', response.data.distributor)
					}
					data.tc_accepted = true
					// data.entityType = 'brand'
					data.entityType = data.role
					dispatch(setUserAction(data))
					dispatch(getEntityDetails(config.entityType, true))
					if (callback)
						callback()
				} else if (response.data.errorMessage) {
					callback(response.data.errorMessage)
				}
			})
	}
}

export const logoutAction = () => {
	return dispatch => {
		return axiosMethodRequest('POST', 'auth/logout', '').then((res) => {
			ClearSessionValue()
			if (res && res.data && res.data.respCode) {
				dispatch(setUserAction(null))
			} else {
				dispatch(setUserAction(null))
			}
		})
	}
}

export const updateUserProfileAction = (data, callback) => {
	return dispatch => {
		let userDetails = GetSessionValue('loginCredentials')
		delete data['email']
		delete data['password']
		return axiosMethodRequest('PUT', `${config.entityType}s/${userDetails['_id']}?type=profile&response=true`, data)
			.then(async (response) => {
				if (response && response.data && response.data.respCode) {
					let data = response.data.details
					data.tc_accepted = true
					data.entityType = data.role
					
					data.accessToken = userDetails.accessToken
					SetSessionValue('user', data)
					SetSessionValue('loginCredentials', data)
					dispatch(setUserAction(data))
					callback(response.data.respMessage, 'success')
				} else {
					callback(response.data.errorMessage, 'error')
				}
			})
	}
}

export const updateSettingsAction = (data, callback) => {
	return dispatch => {
		return axiosMethodRequest('PUT', `settings/${data._id}`, data)
			.then(async (response) => {
				if (response && response.data && response.data.respCode) {
					callback(response.data.respMessage, 'success')
				} else {
					callback(response.data.errorMessage, 'error')
				}
			})
	}
}

export const getSettingsAction = (callback) => {
	return dispatch => {
		let filterCriteria = {}
		filterCriteria.criteria = [{ "key": "active", "value": true, "type": "eq" }]
		let url = `settings?filter=${JSON.stringify(filterCriteria)}`
		return axiosMethodRequest('GET', url).then((res) => {
			if (res && res.data && res.data.respCode) {
				if (res.data.settings) {
					callback(res.data.settings[0])
					dispatch(setSettingsData(res.data.settings[0]))
				}
			}
		})
	}
}

export const sessionExpiryAction = () => {
	return dispatch => {
		SetSessionValue('sessionExpired', false)
		return dispatch(setUserAction(null))
	}
}
